import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-wrap justify-end items-center content-center text-textLight dark:text-textDark text-base w-mobile sm:w-median lg:w-large mx-auto"
}
const _hoisted_2 = { class: "" }
const _hoisted_3 = ["id", "onClick"]
const _hoisted_4 = { href: "#" }
const _hoisted_5 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.dataReady)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supportedLanguages, (language) => {
            return (_openBlock(), _createElementBlock("div", {
              key: language.languageCode
            }, [
              (language.languageCode !== _ctx.activeLanguage.languageCode)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "lang_btn",
                    id: language.name,
                    onClick: ($event: any) => (_ctx.changeLanguage(language))
                  }, [
                    _createElementVNode("a", _hoisted_4, [
                      _createElementVNode("img", {
                        src: _ctx.getFlag(language.languageCode),
                        alt: language.name
                      }, null, 8, _hoisted_5)
                    ])
                  ], 8, _hoisted_3))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}