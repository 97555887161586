<template>
<div class="float_icons">
    <ul>
        <li><ps-link  href="https://api.whatsapp.com/send?phone=01010620200"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'whatsapp' }" style="color:#FFF;font-size:18px"/></ps-link></li>
        <li><ps-link  href="tel:01010620200"><font-awesome-icon icon="phone" style="color:#FFF;font-size:18px"/></ps-link></li>
        <li><ps-link  href="consult-expert" alt="استشر خبيرك" style="font-size: 10px;
                                                                            line-height: 15px;
                                                                            text-align: center;
                                                                            padding-top: 18px;">{{ $t("Consult your expert") }}</ps-link></li>
    </ul>
</div>
<!-- end float_icons <i class="fa-solid fa-question"></i> -->
<footer>
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-5 col-xs-12">
                <img src="@/assets/images/footer_logo.png" class="footer_logo">
                <p class="main_p">
                    {{ $t("about_footer") }}
                </p>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <ul class="info">
                    <li><font-awesome-icon icon="phone" style="color:#FFC124;margin-right: 10px;"/>01010620200</li>
                    <li><font-awesome-icon icon="envelope" style="color:#FFC124;margin-right: 10px;"/>Email@gmail.com</li>
                    <li><font-awesome-icon icon="map" style="color:#FFC124;margin-right: 10px;"/>Cairo Egypt  </li>
					
                </ul>
            </div>
            <div class="col-md-4 col-sm-3 col-xs-12">
                <h4> {{ $t("Quick links") }}</h4>
                <ul class="footer_links">
                    <li>
						<ps-route-link
                                to="item-entry" textColor="text-textLight" textSize="font-light lg:text-sm text-xs"> 
                                {{ $t("Create a post") }}
                        </ps-route-link>
					</li>
                    <li>
						<ps-route-link 
                                :to="{name: 'contact' }" textColor="text-textLight" textSize="font-light lg:text-sm text-xs"> 
                                {{ $t("about us") }}
                        </ps-route-link>
					</li>
                    <li>
						<ps-route-link 
                                to="account-list" textColor="text-textLight" textSize="font-light lg:text-sm text-xs"> 
                                {{ $t("Contacts") }}
                        </ps-route-link>
					</li>
                </ul>
            </div>
        </div>

        <div class="copyright">
            <p>
                {{ $t("Developed by") }}
                <ps-link href="https://www.tqniait.com/">  <img src="@/assets/images/tqnia_logo.png" alt="tqnia" class="cursor-pointer " style="display: inline;width: 68px;height: 25px;"/> 
                </ps-link>
                {{ $t("All rights reserved") }}
            </p>
            <ul class="list-inline">
                <li><ps-link  href="https://api.whatsapp.com/send?phone=01010620200"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'whatsapp' }" style="color:#FFF;font-size:26px"/></ps-link></li>
				<li><ps-link  url="https://www.facebook.com/"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }" style="color:#FFF;font-size:23px"/></ps-link></li>
				<li><ps-link  href="mailto:someone@example.com">  <font-awesome-icon icon="envelope" style="color:#FFF;font-size:23px"/></ps-link></li>
            </ul>
        </div>
    </div>
</footer>
<!-- end footer -->
   
</template>

<script>

// Libs
import { ref } from 'vue';
import router from '@/router';
 import PsLink from '@/components/core/link/PsLink.vue'
import PsRouteLink from '@/components/core/link/PsRouteLink.vue'
//import PsLabelCaption from '@/components/core/label/PsLabelCaption.vue'
// Holders
import { PsValueProvider } from "@/store/modules/core/PsValueProvider";
import { useRecentItemProviderState } from "@/store/modules/item/RecentItemProvider";
import { usePopularItemProviderState } from '@/store/modules/item/PopularItemProvider';
import PsConfig from "@/config/PsConfig";
import { usePsAppInfoProviderState } from '@/store/modules/appinfo/AppInfoProvider';
import AppInfoParameterHolder from '@/object/holder/AppInfoParameterHolder';
export default {
    components : {
         PsLink,
        PsRouteLink,
        //PsLabelCaption
    },
    setup() {
        // Inject Item Provider
        const psValueHolder = PsValueProvider.psValueHolder;
        const locationId = ref(psValueHolder.locationId);
        const versionNo = ref(PsConfig.versionNo);


        // Inject Item Provider
        const itempopularProvider = usePopularItemProviderState();
        const itemrecentProvider = useRecentItemProviderState();
        itempopularProvider.paramHolder.itemLocationId = locationId.value;
        const loginUserId = psValueHolder.getLoginUserId();
        const appInfoProvider = usePsAppInfoProviderState();
        const appInfoParameterHolder = new AppInfoParameterHolder();
        appInfoParameterHolder.userId = loginUserId;
        function gotToHome() {
            router.push({name : "dashboard"});
        }
        appInfoProvider.loadDeleteHistory(appInfoParameterHolder);

        function viewAllRecentItemList() {
            const params = itemrecentProvider.paramHolder.getUrlParamsAndQuery();
            params['name'] = 'item-list';
            
            router.push(params);
            
            
        }

        function viewAllPopularItemList() {

            const params = itempopularProvider.paramHolder.getUrlParamsAndQuery();
            params['name'] = 'item-list';
         
            // Redirect
            router.push(params);
            

        }

        return  {
            gotToHome,
            viewAllPopularItemList,
            viewAllRecentItemList,
            versionNo,
            PsConfig,
            appInfoProvider
        }
    }
}
</script>
