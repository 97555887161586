
export default {
    name : 'PsLabel',
    props: {
        textColor : {
            type: String,               
            default: '',            
        }        
    }
}
